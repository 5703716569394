<template>
    <div id="app">
      <div class="main" :style="Style">
        <h3>點擊下載安裝APP</h3>
        <h3>Click Download to Get Started Installation App</h3>
        <div v-if="this.items.length > 0">
          <div v-for="item in items" :key="item.id">
            <el-container>
              <div v-for="app in item" :key="app.id" class="box">
                <div @click="handler_click">
                  <el-card :style="{ width: '294px', height: '146px', 'margin-top': '1px', 'margin-bottom': '10px', 'margin-left': '1px' }">
                    <el-container>
                      <div>
                        <img :src="app.icon" width="100px" height="100px">
                      </div>
                      <div style="margin-left: 5px;">
                        <div style="font-size: 15px; margin-left: 10px; margin-top: 5px; font-weight: bold;">
                          <Unfold :data="app.alias.hk" :maxLen="15"></Unfold>
                        </div>
                        <div style="font-size: 14px; margin-left: 10px;  margin-top: 2px;">
                          <span>大小: {{ format_size(app.size) }} </span>
                        </div>
                        <div style="font-size: 14px; margin-left: 10px; margin-top: 2px;">
                        <span>版本: </span>
                        <Unfold :data="app.version" :maxLen="12"></Unfold>
                        </div>
                        <div style="font-size: 16px; margin-left: 10px; margin-top: 2px;">
                          {{ app.name }}
                          <el-link icon="el-icon-download" :href="app.url"  type="primary" :download=format_name(app)>点击下载</el-link>
                        </div>
                      </div>
                    </el-container>
                  </el-card>
                </div>
              </div>
            </el-container>
          </div>
        </div>
   
      </div>
    </div>

</template>
<script>
import axios from "axios";
import Unfold from '@/components/Unfold.vue'

export default {
  name: 'App',

  width: 0,
  max: 3,

  components: {
    Unfold
  },
  
  data() {
    return {
      apps: [],
      items: [],
      home_image: "url(" + require("@/assets/home.jpg") + ")"
    }
  },

  methods: {
    // 点击应用框事件
    handler_click() {
      // console.log("你点击我拉")
    },

    // 选中应用框事件
    handler_hover() {
      // console.log("你选中我拉")
    },

    format_name(app) {
      var app_name = app.alias.default
      if (app.alias.default == "") {
        app_name = app.package_name;
      }

      if (app.version === "" || app.version === undefined) {
        return app_name + ".apk"
      }

      app_name += "-";
      if (!app.version.startsWith("-")){
        app_name += "v";
      }

      app_name += app.version + ".apk";

      return app_name
    },

    format_size(bytes, decimalPoint = 2) {
      if (bytes === 0) {
        return '0 B'
      }

      var k = 1024
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      var n = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, n)).toFixed(decimalPoint)) + ' ' + sizes[n]
    },


    GetApps() {
      axios.get("/api/v1/webstore").then((data) => {
        if (data.data.code !== 0) {
          return
        }

        var apps = JSON.parse(data.data.payload)
        this.apps = apps
        var index = 0;
        var sum = 0;
        this.items = [[]]
        for (let n=0; n < this.apps.length; n++) {
          if (sum > this.max) {
            index+=1
            sum = 0
            this.items.push([])
          }

          this.items[index].push(this.apps[n])
          sum++
        }

      }).catch((err) => {
        });
    }
  },

  created() {
    this.max = 3
    var width = window.innerWidth
    if (width >= 1280) {
      this.max = 3
      this.width = 1240
    }else if (width >= 960){
      this.max = 2
      this.width = 960
    }else if (width >= 640) {
      this.width = 640
      this.max = 1
    }else{
      this.width = window.innerWidth
      this.max = 0
    }
    this.GetApps()
  },

  computed: {
    Style() {
      return {
        width: this.width + "px"
      }
    }
  },

  beforeCreate () {
    this.$nextTick(()=>{
      document.body.setAttribute('style', 'background:#f0f0f0')
    })
  },

}
</script>

<style>
  .main {
    margin: auto;
    /* width: 1240px; */
  }

  .box {
    width: 300px;
    height: 150px;
    /* margin: auto; */
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid #EBEEF5;
  }

  .box:hover {
    /* background-color: #409EFF; */
    border: 2px solid #409EFF;
  }

</style>
